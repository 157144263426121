<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-subtitle>
          <v-chip-group
            column
          >
            <v-chip
              color="red"
              text-color="white"
            >
              {{$t('general.absent')}}
            </v-chip>
            <v-chip
              color="green"
              text-color="white"
            >
              {{$t('general.present')}}
            </v-chip>
          </v-chip-group>
        </v-card-subtitle>
        <v-card-text>
          <div>
            <v-sheet
              tile
              height="54"
              color="grey lighten-3"
              class="d-flex"
            >
              <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.prev()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-spacer />
              <v-card-title>
                {{$t('general.presence_control')}}
                <v-toolbar-title v-if="$refs.calendar" class="ml-3">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
              </v-card-title>
              <v-spacer />
              <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.next()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-sheet>
            <v-sheet height="600">
              <v-progress-linear
                v-if="!Lb_IsLoaded"
                class="my-1"
                indeterminate
                color="primary"
              />
              <!-- {0} show the Count of more events in the Table Column -->
              <v-calendar
                :event-more-text="'{0} ' +$t('general.more')"
                locale="CH"
                color="primary"
                ref="calendar"
                v-model="value"
                :interval-format="intervalFormat"
                :first-interval= 7
                :interval-count= 17
                :weekdays="weekdays"
                type="week"
                :events="La_Presence"
                event-overlap-mode="column"
                :event-color="getEventColor"
              />
            </v-sheet>
          </div>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { get } from '../../../worker/worker-api';
import { mapGetters } from 'vuex'
export default {
  metaInfo() {
    return{
      // title will be injected into parent titleTemplate
      title: this.$t('general.presence_control'),
    }
  },
  props: {
    // AdressRolleID from Child
    childId: {
      type: Number,
      default: 0
    }
  },    
  data () {
    return {
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      value: '',
      La_Presence: [],
      Lb_IsLoaded: false,
      i_AddressRoleID: null,
    }
  },
  watch: {
    '$store.state.authData.o_CurrentPlanningPeriod.planningPeriodID': function() {
      if (this.childId != 0){
      this.getPresences(this.childId, this.getPlanningPeriodID);
      }// if
      else{
        this.getPresences(this.i_AddressRoleID, this.getPlanningPeriodID);
      }// else
    }
  },
  computed: {
    ...mapGetters(['getPlanningPeriodID', 'getImpersonation']),
  },
  methods: {
    getPresences(Ai_SSP_AddressRoleID,Ai_PlanningPeriodID) {
      if (Ai_SSP_AddressRoleID === null) return
      store.dispatch('changeThemeLoadingState', true);
      this.Lb_IsLoaded =false;
      return get(`${process.env.VUE_APP_SMT_API_URL}/student/${Ai_SSP_AddressRoleID}/presence/planning-period/${Ai_PlanningPeriodID}`, {})
        .then(response => {
          this.La_Presence = response.presences;
          setTimeout(() => store.dispatch('changeThemeLoadingState', false), 700)
          this.Lb_IsLoaded = true;
        }).catch(error => {
          this.Lb_IsLoaded = true;
          store.dispatch('changeThemeLoadingState', false);
        });
    },

    getEventColor (event) {
      return event.color
    },

    intervalFormat(interval) {//get 24H format
      return interval.time
    },
    
  },

  beforeMount () {
    if (this.getImpersonation.b_Status) this.i_AddressRoleID = this.getImpersonation.i_AddressRoleID
    else this.i_AddressRoleID = this.$smt.getAddressRoleID(this.$route.meta.role)
    if (this.childId != 0){
    this.getPresences(this.childId, this.getPlanningPeriodID);
    }// if
    else{
      this.getPresences(this.i_AddressRoleID, this.getPlanningPeriodID);
    }//else
  },
}
</script>
